const About = () => {
  return (
    <div id="about" className="about">
      <h1 className="about__title">About</h1>
      <div className="about__container">
        <div className="about__grid">
          <h3 className="about__second-header">Why you should get involved:</h3>
          <ul className="about__list">
            <li className="about__list__item">
              POMM can provide excellent additional support for those with MH
              challenges and their families
            </li>
            <li className="about__list__item">
              Waitlists for therapists can be long…in the meantime get involved
              with mentoring!
            </li>
            <li className="about__list__item">
              There are few to no adult-only one-on-one mentoring programs for
              mental health support and companionship in existence, join the
              movement and help fill this gap in services!
            </li>
            <li className="about__list__item">
              Help break the stigma surrounding mental health
              challenges/diagnoses within the adult population
            </li>
            <li className="about__list__item">
              Support, uplift, and encourage those who may be dealing with
              similar challenges
            </li>
            <li className="about__list__item">
              Similar to a sponsorship, provide consistency and support to
              someone in need that you can relate to!
            </li>
            <li className="about__list__item">
              Everyone deserves access to a mentor, including adults with mental
              health challenges!
            </li>
            <li className="about__list__item">
              Not everyone enjoys support groups with multiple people,
              one-on-one mentorship may be right for you
            </li>
            <li className="about__list__item">
              Connect with someone who has your same diagnosis/challenges,
              someone who understands/can relate to what you are going through
            </li>
            <li className="about__list__item">
              FIND PEACE IN KNOWING YOU ARE NOT ALONE
            </li>
          </ul>
          <div className="about__description">
            Peace of Mind Mentoring is an online mentoring network for mental
            health support and companionship. We serve adults (18+) with mental
            health challenges. We also serve affected family members (18+)! Once
            an application is submitted, a thoughtful matching process will take
            place to connect mentees and mentors. Thank you for your patience
            and encouragement!
            <br />
            <br />
            If you are a family member who is interested in being matched with
            another family member please note this on the application. Families
            deserve support too!
          </div>
          <div className="about__image-container">
            <img
              className="about__image"
              src="./media/about.jpg"
              alt="friends sitting on the grass"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
