const Banner = () => {
  return (
    <div className="banner" id="banner">
      <div className="banner__bg_video">
        <video className="banner__bg-video__content" autoPlay muted loop>
          <source src="media/water.mp4" type="video/mp4" />
          Your browser is not supported!
        </video>
      </div>

      <div className="banner__description-box">
        <div>
          <img className="banner__logo" src="./media/logo.png" alt="logo" />
        </div>

        <h1 className="banner__primary-heading">Peace of Mind Mentoring</h1>
        <h4 className="banner__secondary-heading">
          Find peace in knowing you are not alone
        </h4>
      </div>
    </div>
  );
};

export default Banner;
