const Mentee = () => {
  return (
    <div id="mentee" className="mentee">
      <h1 className="mentee__title">Mentee</h1>
      <div className="mentee__container">
        <div className="mentee__grid">
          <div className="mentee__description-one">
            Who should apply: Adults (18+) who are interested in having an adult
            mentor who can relate to similar mental health challenges. If you
            have a mental health diagnosis and think you could benefit from a
            mentor's support and companionship, fill out an application today!
          </div>
          <div className="mentee__image-container">
            <div className="mentee__image">
              <img
                className="mentee__image"
                src="./media/mentee.jpg"
                alt="woman depressed"
              />
            </div>
          </div>
          <div className="mentee__description-two">
            Mentee application process: Once a mentee application is submitted,
            a thoughtful matching process will occur. Your age, gender, specific
            mental health challenges, communication style, and any other noted
            mentor preferences will be taken into consideration. Both parties
            will be notified of the match through email. This process may take
            days or weeks. Once matched, you can reach out and start talking
            with your mentor!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentee;
