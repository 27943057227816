import Nav from "./components/Nav";
import Banner from "./components/Banner";
import About from "./components/About";
import Mentee from "./components/Mentee";
import Mentor from "./components/Mentor";
import Application from "./components/Application";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import useWindowDimensions from "./hooks/windowSize";

const App = () => {
  const { width } = useWindowDimensions();
  return (
    <div className="app">
      <Nav size={width} />
      <div className={width <= 600 ? "" : "dummy"} id="home"></div>
      <Banner />
      <About />
      <Mentee />
      <Mentor />
      <Application />
      <Contact />

      <Footer />
    </div>
  );
};

export default App;
