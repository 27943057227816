import { useState } from "react";
import { useForm } from "react-hook-form";
import Err from "./Err";
import emailjs from "emailjs-com";
import { env } from "../vars/config";

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
  });
  const { REACT_APP_EMAILJS_USERID, REACT_APP_EMAILJS_TEMPLATEID_TWO } = env;
  const onSubmit = (data) => {
    if (data) {
      setSuccess(true);
      emailjs.send(
        "gmail",
        REACT_APP_EMAILJS_TEMPLATEID_TWO,
        data,
        REACT_APP_EMAILJS_USERID
      );
      reset({
        name: "",
        applying: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  };

  return (
    <div id="contact" className="contact">
      <h1 className="contact__title">Contact</h1>
      <h4 className="contact__title">All fields with a * are required</h4>
      {success ? (
        <h1 className="contact__title">Form submitted successfully!</h1>
      ) : (
        <></>
      )}
      <form className="contact__form" onSubmit={handleSubmit(onSubmit)}>
        <label className="contact__form__label">
          Name:
          <input
            className="contact__form__input"
            type="text"
            placeholder="John Doe"
            {...register("name", {
              required: {
                value: true,
                message: "Name is required!",
              },
              maxLength: {
                value: 77,
                message: "The max amount of characters has been exceeded.",
              },
              minLength: {
                value: 2,
                message: "Please enter your name!",
              },
            })}
          />
          *{errors.name ? <Err errors={errors} error={errors.name} /> : <Err />}
        </label>
        <label className="contact__form__label">
          Email:
          <input
            className="contact__form__input"
            type="text"
            placeholder="example@example.com"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required!",
              },
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Please enter a valid email",
              },
            })}
          />
          *
          {errors.email ? (
            <Err errors={errors} error={errors.email} />
          ) : (
            <Err />
          )}
        </label>
        <label className="contact__form__label">
          Phone Number:
          <input
            className="contact__form__input"
            type="tel"
            placeholder="555-555-5555"
            {...register("phoneNumber", {
              maxLength: {
                value: 12,
                message: "Please enter a valid phone number!",
              },
            })}
          />
          {errors.phoneNumber ? (
            <Err errors={errors} error={errors.phoneNumber} />
          ) : (
            <Err />
          )}
        </label>
        <label className="contact__form__label">Message: *</label>
        <textarea
          className="contact__form__input contact__form__input__text"
          type="text"
          placeholder="Enter your message here!"
          {...register("message", {
            required: {
              value: true,
              message: "Please enter a message!",
            },
            minLength: {
              value: 2,
              message: "Please enter a message!",
            },
            maxLength: {
              value: 500,
              message: "The maximum number of characters has been reached!",
            },
          })}
        />

        {errors.message ? (
          <Err errors={errors} error={errors.message} />
        ) : (
          <Err />
        )}
        <input className="contact__form__button" type="submit" />
      </form>
    </div>
  );
};

export default Contact;
