import { useState } from "react";
import { useForm } from "react-hook-form";
import Err from "./Err";
import emailjs from "emailjs-com";
import { env } from "../vars/config";

const Application = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      applying: "",
      email: "",
      phoneNumber: "",
      health: "",
      dateOfBirth: "",
      gender: "",
      communication: "",
      inPerson: "",
      hearAboutUs: "",
      interested: "",
    },
  });
  const [success, setSuccess] = useState(false);
  const { REACT_APP_EMAILJS_USERID, REACT_APP_EMAILJS_TEMPLATEID } = env;
  const onSubmit = (data) => {
    if (data) {
      setSuccess(true);
      emailjs.send(
        "gmail",
        REACT_APP_EMAILJS_TEMPLATEID,
        data,
        REACT_APP_EMAILJS_USERID
      );
      reset({
        firstName: "",
        lastName: "",
        applying: "",
        email: "",
        phoneNumber: "",
        health: "",
        dateOfBirth: "",
        gender: "",
        communication: "",
        inPerson: "",
        hearAboutUs: "",
        interested: "",
      });
    }
  };

  return (
    <div id="application" className="application">
      <h1 className="application__title">Application</h1>
      <h4 className="application__title">All fields with a * are required</h4>
      {success ? (
        <h1 className="application__title">Form submitted successfully!</h1>
      ) : (
        <></>
      )}

      <form className="application__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="application__form__radio">
          <label>Are you applying to be a:</label>
          <label className="application__form__radio__label">Mentor</label>
          <input
            {...register("applying", {
              required: {
                value: true,
                message: "Please select Mentor or Mentee!",
              },
            })}
            type="radio"
            value="Mentor"
          />
          <label className="application__form__radio__label">Mentee</label>
          <input
            {...register("applying", {
              required: {
                value: true,
                message: "Please select Mentor or Mentee!",
              },
            })}
            type="radio"
            value="Mentee"
          />
          *
          {errors.applying ? (
            <Err errors={errors} error={errors.applying} />
          ) : (
            <Err />
          )}
        </div>
        <label>
          First name:
          <input
            className="application__form__input"
            type="text"
            placeholder="Jane"
            {...register("firstName", {
              required: {
                value: true,
                message: "First name is required!",
              },
              maxLength: {
                value: 50,
                message: "First name is too long!",
              },
              minLength: {
                value: 2,
                message: "First name too short!",
              },
            })}
          />
          *
          {errors.firstName ? (
            <Err errors={errors} error={errors.firstName} />
          ) : (
            <Err />
          )}
        </label>

        <label>
          Last name:
          <input
            className="application__form__input"
            type="text"
            placeholder="Doe"
            {...register("lastName", {
              required: {
                value: true,
                message: "Last name is required!",
              },
              maxLength: {
                value: 50,
                message: "Last name is too long!",
              },
              minLength: {
                value: 2,
                message: "Last name too short!",
              },
            })}
          />
          *
          {errors.lastName ? (
            <Err errors={errors} error={errors.lastName} />
          ) : (
            <Err />
          )}
        </label>

        <label>
          Email:
          <input
            className="application__form__input"
            type="text"
            placeholder="example@example.com"
            {...register("email", {
              required: {
                value: true,
                message: "A valid email is required!",
              },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                message: "Please enter a valid email!",
              },
            })}
          />
          *{errors ? <Err errors={errors} error={errors.email} /> : <Err />}
        </label>
        <label>
          Phone Number:
          <input
            className="application__form__input"
            type="tel"
            placeholder="555-555-5555"
            {...register("phoneNumber", {
              maxLength: {
                value: 12,
                message: "The maximum allowed is 12 digits!",
              },
              pattern: {
                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                message: "Please enter a valid phone number!",
              },
            })}
          />
          {errors.phoneNumber ? (
            <Err errors={errors} error={errors.phoneNumber} />
          ) : (
            <Err />
          )}
        </label>
        <label>
          Date of birth:
          <input
            className="application__form__input"
            type="text"
            placeholder="01/01/2000"
            {...register("dateOfBirth", {
              required: {
                value: true,
                message: "Please enter your date of birth!",
              },
              maxLength: {
                value: 12,
                message: "Please enter your date of birth as: mm/dd/yyyy",
              },
              minLength: {
                value: 8,
                message: "Please enter your date of birth as mm/dd/yyyy",
              },
            })}
          />
          *
          {errors.dateOfBirth ? (
            <Err errors={errors} error={errors.dateOfBirth} />
          ) : (
            <Err />
          )}
        </label>
        <label>
          Gender:
          <input
            className="application__form__input"
            type="text"
            placeholder="Gender"
            {...register("gender", {
              required: {
                value: true,
                message: "Please enter your gender",
              },
              maxLength: {
                value: 20,
                message: "You've exceeded the number of characters allowed!",
              },
              minLength: {
                value: 2,
                message: "Please enter your gender",
              },
            })}
          />
          *
          {errors.gender ? (
            <Err errors={errors} error={errors.gender} />
          ) : (
            <Err />
          )}
        </label>
        <label>Mental Health Diagnosis/History/Challenges: *</label>
        <textarea
          className="application__form__input__text"
          type="text"
          placeholder="Mental Health Diagnosis/History/Challenges"
          {...register("health", {
            required: {
              value: true,
              message: "Don't forget to tell us about you!",
            },
            minLength: {
              value: 2,
              message: "Please tell us more about you!",
            },
            maxLength: {
              value: 500,
              message: "The maximum character length is 500!",
            },
          })}
        />
        {errors.health ? (
          <Err errors={errors} error={errors.health} />
        ) : (
          <Err />
        )}
        <label>
          Communication preferences:
          <input
            className="application__form__input"
            type="text"
            placeholder="call/text/email/ect."
            {...register("communication", {
              minLength: {
                value: 2,
                message: "Communication style is too short!",
              },
              maxLength: {
                value: 20,
                message: "Communication style is too long!",
              },
            })}
          />
          {errors.communication ? (
            <Err errors={errors} error={errors.communication} />
          ) : (
            <Err />
          )}
        </label>

        <div className="application__form__radio">
          <label>
            Would you be interested in meeting in-person eventually?: *
          </label>

          <div>
            <label className="application__form__radio__label">Yes</label>
            <input
              type="radio"
              {...register("inPerson", {
                required: {
                  value: true,
                  message: "Please select Yes or No!",
                },
              })}
              value="Yes"
            />

            <label className="application__form__radio__label">No</label>
            <input
              type="radio"
              {...register("inPerson", {
                required: {
                  value: true,
                  message: "Please select Yes or No!",
                },
              })}
              value="No"
            />
            {errors.inPerson ? (
              <Err errors={errors} error={errors.inPerson} />
            ) : (
              <Err />
            )}
          </div>
        </div>
        <label>How did you hear about POMM? </label>
        <textarea
          className="application__form__input__text"
          type="text"
          placeholder="How did you hear about POMM?"
          {...register("hearAboutUs", {
            minLength: {
              value: 2,
              message: "Tell us more!",
            },
            maxLength: {
              value: 100,
              message: "The maximum amount of characters is 100!",
            },
          })}
        />
        {errors.hearAboutUs ? (
          <Err errors={errors} error={errors.hearAboutUs} />
        ) : (
          <Err />
        )}

        <label>Why are you interested in POMM?: *</label>
        <textarea
          type="text"
          className="application__form__input__text"
          placeholder="Why are you interested in POMM?"
          {...register("interested", {
            required: {
              value: true,
              message: "Tell us why you're interested in POMM!",
            },
            minLength: {
              value: 2,
              message: "Tell us why you're interested in POMM!",
            },
            maxLength: {
              value: 200,
              message: "The maximum number of characters is 200!",
            },
          })}
        />
        {errors.interested ? (
          <Err errors={errors} error={errors.interested} />
        ) : (
          <Err />
        )}

        <input className="application__form__button" type="submit" />
      </form>
    </div>
  );
};

export default Application;
