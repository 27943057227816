import { IoIosMail } from "react-icons/io";

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <h3>&copy; 2022 by Peace of Mind Mentoring.</h3>
      <h4 className="footer__email">
        <IoIosMail className="footer__icon" />{" "}
        <a href="mailto: peaceofmindmentoringinfo@gmail.com">
          peaceofmindmentoringinfo@gmail.com
        </a>
      </h4>
      <h6 className="footer__nate">
        Website by:{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.natemailhiot.com/"
        >
          Nathan Mailhiot
        </a>
      </h6>
    </div>
  );
};

export default Footer;
