import { ErrorMessage } from "@hookform/error-message";
const Err = ({ error, errors }) => {
  return (
    <>
      {error ? (
        <ErrorMessage
          errors={errors}
          name={error.ref.name}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p className="errors__message" key={type}>
                {message}
              </p>
            ))
          }
        />
      ) : (
        []
      )}
    </>
  );
};

export default Err;
