import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";

const Nav = ({ size }) => {
  const [menu, setMenu] = useState(size);

  if (size > 600 && menu === "hidden") {
    setMenu("show");
  }

  const onClick = () => {
    if (menu === "hidden") {
      setMenu("show");
    } else {
      setMenu("hidden");
    }
  };

  return (
    <div id="nav">
      <div className={`${menu} nav`}>
        <ul>
          {size < 601 ? (
            <li>
              <div className="mobile">
                {menu === "hidden" ? (
                  <GiHamburgerMenu className="hamburger" onClick={onClick} />
                ) : (
                  <IoClose className="close" onClick={onClick} />
                )}
              </div>
            </li>
          ) : (
            <></>
          )}
          <li>
            <a className={menu} href="#home">
              Home
            </a>
          </li>
          <li>
            <a className={menu} href="#about">
              About
            </a>
          </li>
          <li>
            <a className={menu} href="#mentee">
              Mentee
            </a>
          </li>
          <li>
            <a className={menu} href="#mentor">
              Mentor
            </a>
          </li>
          <li>
            <a className={menu} href="#application">
              Application
            </a>
          </li>
          <li>
            <a className={menu} href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Nav;
