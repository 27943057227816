const Mentor = () => {
  return (
    <div id="mentor" className="mentor">
      <div className="mentor__container">
        <h1 className="mentor__title">Mentor</h1>
        <div className="mentor__container">
          <div className="mentor__grid">
            <div className="mentor__description-one">
              Who should apply: Adults (18+) who are interested in providing
              support and companionship to an 18+ mentee. The mentee may have
              similar mental health challenges that you have been able to
              overcome yourself. If you have a mental health diagnosis/past
              mental health challenges and think you could provide support and
              companionship to a mentee who may be experiencing similar. Those
              who are certain they will be able to consistently reach out to
              connect with their mentee (weekly, bi-weekly, etc.)
            </div>
            <div className="mentor__image-container">
              <div className="mentor__image">
                <img
                  className="mentor__image"
                  src="./media/mentor.jpg"
                  alt="people supporting each other"
                />
              </div>
            </div>
            <div className="mentor__description-two">
              Mentor application process: Once a mentor application is
              submitted, a thoughtful matching process will occur. Your age,
              gender, specific mental health challenges/history, communication
              style, and any other noted mentee preferences will be taken into
              consideration. Both parties will be notified of the match through
              email. This process may take days or weeks. Once matched, you can
              reach out and start talking with your mentee!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentor;
